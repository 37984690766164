import React, { useEffect, useState, useContext } from "react"
import * as styles from "./header.module.scss"
import { Link, navigate } from "gatsby"
import { MdKeyboardArrowDown } from "react-icons/md"
// import Search from "./../../../images/search.svg"
// import Avtar from "./../../../images/user.png"
// import Bell from "./../../../images/bell.svg"
// import Chat from "./../../../images/chat.svg"
// import Setting from "./../../../images/setting.svg"

import { useLink, useSiteQuery } from "../../../hooks"
import { GatsbyImage } from "gatsby-plugin-image"

import Dropdown from "../../../static/dropdown.svg"
import DropdownLight from "../../../static/dropdownlight.svg"

import Back from "../../../static/back-arrow.svg"
import DarkBack from "../../../static/dark-back-arrow.svg"

import SunIcon from "../../../static/sun-icon.svg"
import MoonIcon from "../../../static/moon-icon.svg"

import ProfileModal from "../Modal/ProfileModal/ProfileModal"
import firebase from "gatsby-plugin-firebase"

import { ProfileContext } from "../../../context/profile_data"
import SearchHeader from "../../SearchComponent/SearchHeader/SearchHeader"
// import firebase from "gatsby-plugin-firebase"
// import { AuthContext } from "../../../context/auth"

import Search from "../../../images/searchIcon.svg"
import Profile from "../../../images/Profile.svg"
import Ticket from "../../../images/ticket.svg"
import Rectangle from "../../../images/rectangle.svg"

import { useLocation } from "@reach/router"
// import NotificationModal from "../Modal/NotificationModal/NotificationModal"
import AnnouncementBar from "../Modal/SliderNotification/AnnouncementBar"
import { LayoutContext } from "../../../context/context"
import ButtonLink from "../../shared/ButtonLink/ButtonLink"

import { shouldShowDarkModeToggle } from "../../../hooks/function/navigationUtils"

import { FaChevronDown, FaChevronRight } from "react-icons/fa"

import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  StoreHelpers,
} from "react-joyride"
import { LocalStorage, LocalStorageKeys } from "../../../utils"

const Header = () => {
  const [openModal, setOpenModal] = useState(false)
  const {
    headerLogo,
    darkThemeHeaderLogo,
    headerItems,
    headerButton,
    announcementsArray,
  } = useSiteQuery()
  const { profile } = useContext(ProfileContext)
  const [searchText, setSearchText] = useState("")
  const [searchOpen, setSearchOpen] = useState(false)
  const location = useLocation()
  const { quickView, setQuickView, isDarkMode, setIsDarkMode } =
    useContext(LayoutContext)

  useEffect(() => {
    const storedDarkMode = LocalStorage.getDarkMode()
    setIsDarkMode(storedDarkMode)
  }, [])

  const onClickProfileModal = e => {
    if (document.getElementById("header-profile-logo")?.contains(e.target)) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
  }

  const handleDarkModeToggle = () => {
    const newDarkMode = !isDarkMode
    setIsDarkMode(newDarkMode)
    LocalStorage.setDarkMode(newDarkMode)
  }

  useEffect(() => {
    window.addEventListener("click", onClickProfileModal)
    return () => {
      window.removeEventListener("click", onClickProfileModal)
    }
  }, [location])

  const openCloseView = () => {
    setQuickView(!quickView)
  }

  const showDarkModeToggle = shouldShowDarkModeToggle(location.pathname)

  return (
    <header>
      <div className={styles.headerContainer} id="header-desktop-container">
        <div className={styles.topHeaderBar} id="header-div">
          <div className={styles.empty}>&nbsp;</div>
          <Search
            id="search-icon"
            tabIndex={0}
            onClick={() => setSearchOpen(true)}
            className={`${styles.headerBarIcon} ${styles.toolTipHide}`}
          />
          <Ticket
            id="ticket-icon"
            tabIndex={0}
            className={`styles.ticketIcon ${styles.headerBarIcon} ${styles.toolTipHide}`}
            onClick={() => openCloseView()}
          />
          <div
            id="header-profile-logo"
            tabIndex={0}
            className={`${styles.profileHeaderIconContainer} ${styles.toolTipHide}`}
          >
            <div
              id="profile-icon"
              onClick={() => setOpenModal(true)}
              className={styles.profileInfo}
            >
              <span tabIndex={0}>Hi, {profile?.firstName}</span>
              <Profile tabIndex={0} />
            </div>
            {openModal ? (
              <div className={`styles.profileModal`}>
                {" "}
                <ProfileModal setOpen={setOpenModal} />{" "}
              </div>
            ) : null}
          </div>
          {/* {showDarkModeToggle && (
            <div tabIndex={0} id="dark-mode" className="dark-mode-toggle">
              <input
                type="checkbox"
                id="dark-mode-toggle-input"
                checked={isDarkMode}
                onChange={handleDarkModeToggle}
                role="switch"
                aria-checked={isDarkMode}
              />
              <label
                htmlFor="dark-mode-toggle-input"
                className="toggle-label"
                data-theme={isDarkMode ? "Light Theme" : "Dark Theme"}
              ></label>
            </div>
          )} */}
        </div>
        <div className={`${styles.container} dark-color`}>
          <div className={styles.headerLogo}>
            <Link to={`/${useLink("/")}`}>
              <GatsbyImage
                objectFit="contain"
                image={
                  isDarkMode
                    ? darkThemeHeaderLogo.gatsbyImageData
                    : headerLogo.gatsbyImageData
                }
                alt={headerLogo.title}
              />
            </Link>
          </div>
          <div>
            <ul className={styles.navigation}>
              {headerItems.map((item, index) => {
                switch (item.__typename) {
                  case "ContentfulComponentLink":
                    return (
                      <li key={index} className="list-item">
                        <Link
                          to={`/${useLink(item.target?.slug || item.slug)}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    )
                  case "ContentfulComponentLinkGroup":
                    return <SideMenu key={index} data={item} />
                  default:
                    return null
                }
              })}

              <ButtonLink
                id="header-helpdesk"
                title={headerButton.title}
                slug={headerButton.slug}
                target={headerButton.target?.slug}
                extraClass={styles.topnavBtn}
              />
            </ul>
          </div>
        </div>
        <AnnouncementBar announcementsArray={announcementsArray} />
      </div>

      <MobileHeader />
      {searchOpen ? (
        <SearchHeader
          open={searchOpen}
          value={searchText}
          setOpen={setSearchOpen}
          setValue={setSearchText}
        />
      ) : null}
    </header>
  )
}

const MobileHeader = () => {
  const { profile } = useContext(ProfileContext)
  const { setProfile } = useContext(ProfileContext)
  const location = useLocation()

  const showDarkModeToggle = shouldShowDarkModeToggle(location.pathname)

  const closeMain = open => {
    if (!open) {
      document.querySelectorAll(".input-box").forEach(element => {
        element.checked = false
      })
    }
  }

  const logout = async () => {
    await firebase.auth().signOut()

    localStorage.clear()
    setProfile(null)
  }

  const { headerLogo, headerItems, headerButton } = useSiteQuery()

  const { isDarkMode, setIsDarkMode } = useContext(LayoutContext)

  const handleDarkModeToggle = () => {
    const newDarkMode = !isDarkMode
    setIsDarkMode(newDarkMode)
    LocalStorage.setDarkMode(newDarkMode)
  }

  // const [showModal, setShowModal] = useState(false);

  // const toggleTheme = () => {
  //     setShowModal(!showModal);
  // };

  // const selectTheme = (theme) => {
  //     setIsDarkMode(theme === 'dark');
  //     setShowModal(false);
  // };

  const [isOpen, setIsOpen] = useState(true)
  const [selectedItem, setSelectedItem] = useState("all")

  const toggleOverview = () => {
    setIsOpen(!isOpen)
  }

  const handleItemClick = (item, e) => {
    //e.preventDefault() // Prevent default link behavior
    setSelectedItem(item)
  }

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.mobileContainerRight}>
        <input
          type="checkbox"
          id="naviToggle"
          className={`${styles.navigation__checkbox} input-box`}
          hidden
          onChange={e => closeMain(e.target.checked)}
        />
        <label htmlFor="naviToggle" className={styles.navigation__button}>
          <span className={styles.navigation__icon}>&nbsp;</span>
        </label>
        <Link to={`/${useLink("/")}`}>
          <div className={styles.headerLogo}>
            <GatsbyImage
              image={headerLogo.gatsbyImageData}
              alt={headerLogo.title}
              className={styles.image}
            />
          </div>
        </Link>
        <div className={styles.mobileSearchContainer}>
          <a href="/search">
            <span className={styles.mobileSvg}>
              <Search
                id="search-icon-mobile"
                className={styles.toolTipHideMobile}
              />
            </span>
          </a>

          <a href="/ticket/">
            <span className={styles.Ticketsvg}>
              <Ticket
                id="ticket-icon-mobile"
                className={`styles.ticketIcon ${styles.toolTipHideMobile}`}
              />
            </span>
          </a>
        </div>
        <ul className={`${styles.list} dark-color`}>
          {/* {headerItems.map((item, index) => {
            console.log("headerItems", headerItems)
            switch (item.__typename) {
              case "ContentfulComponentLink":
                return (
                  <li key={index}>
                    <Link
                      to={`/${useLink(item.target?.slug || item.slug)}`}
                      onClick={() => closeMain(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              case "ContentfulComponentLinkGroup":
                return (
                  <li key={index}>
                    {item.link.target?.slug || item.link.slug ? (
                      <Link
                        to={`/${useLink(
                          item.link.target?.slug || item.link.slug
                        )}`}
                        onClick={() => closeMain(false)}
                      >
                        {item.link.title}
                      </Link>
                    ) : (
                      <Link onClick={() => closeMain(false)}>
                        {item.link.title}
                      </Link>
                    )}
                    <input
                      type="checkbox"
                      name=""
                      className={`${styles.subListCheckbox} input-box `}
                      id={`mobile-header-${index}`}
                      hidden
                    />
                    {isDarkMode ? (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <DropdownLight />
                      </label>
                    ) : (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <Dropdown />
                      </label>
                    )}
                    <ul className={`${styles.subList} dark-color`}>
                      <li>
                        <label htmlFor={`mobile-header-${index}`}>
                          {isDarkMode ? <DarkBack /> : <Back />}
                          <span>back</span>
                        </label>
                      </li>

                      {item.sections.map((section, index) => (
                        <li key={index}>
                          <Link
                            to={`/${section.target?.slug || section.slug}`}
                            onClick={() => closeMain(false)}
                          >
                            {section.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
            }
          })} */}
          {/* revised Code v-1 */}
          {/* {headerItems.map((item, index) => {
            console.log("headerItems", headerItems)
            switch (item.__typename) {
              case "ContentfulComponentLink":
                return (
                  <li key={index}>
                    <Link
                      to={`/${useLink(item.target?.slug || item.slug)}`}
                      onClick={() => closeMain(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              case "ContentfulComponentLinkGroup":
                return (
                  <li key={index}>
                    {item.link.target?.slug || item.link.slug ? (
                      <Link
                        to={`/${useLink(
                          item.link.target?.slug || item.link.slug
                        )}`}
                        onClick={() => closeMain(false)}
                      >
                        {item.link.title}
                      </Link>
                    ) : (
                      <Link onClick={() => closeMain(false)}>
                        {item.link.title}
                      </Link>
                    )}
                    <input
                      type="checkbox"
                      name=""
                      className={`${styles.subListCheckbox} input-box `}
                      id={`mobile-header-${index}`}
                      hidden
                    />
                    {isDarkMode ? (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <DropdownLight />
                      </label>
                    ) : (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <Dropdown />
                      </label>
                    )}
                    <ul className={`${styles.subList} dark-color`}>
                      <li>
                        <label htmlFor={`mobile-header-${index}`}>
                          {isDarkMode ? <DarkBack /> : <Back />}
                          <span>back</span>
                        </label>
                      </li>

                      {item.sections.map((section, sectionIndex) => (
                        <li key={sectionIndex}>
                          <Link
                            to={`/${section.target?.slug || section.slug}`}
                            onClick={() => closeMain(false)}
                          >
                            {section.title}
                          </Link>
                          {section.sections && (
                            <>
                              <input
                                type="checkbox"
                                name=""
                                className={`${styles.subListCheckbox} input-box `}
                                id={`mobile-header-${index}-${sectionIndex}`}
                                hidden
                              />
                              <label
                                htmlFor={`mobile-header-${index}-${sectionIndex}`}
                                className={styles.svg}
                              >
                                {isDarkMode ? <DropdownLight /> : <Dropdown />}
                              </label>
                              <ul className={`${styles.subList} dark-color`}>
                                <li>
                                  <label
                                    htmlFor={`mobile-header-${index}-${sectionIndex}`}
                                  >
                                    {isDarkMode ? <DarkBack /> : <Back />}
                                    <span>back</span>
                                  </label>
                                </li>

                                {section.sections.map(
                                  (subSection, subSectionIndex) => (
                                    <li key={subSectionIndex}>
                                      <Link
                                        to={`/${
                                          subSection.target?.slug ||
                                          subSection.slug
                                        }`}
                                        onClick={() => closeMain(false)}
                                      >
                                        {subSection.title}
                                      </Link>
                                      {subSection.sections && (
                                        <>
                                          <input
                                            type="checkbox"
                                            name=""
                                            className={`${styles.subListCheckbox} input-box `}
                                            id={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}`}
                                            hidden
                                          />
                                          <label
                                            htmlFor={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}`}
                                            className={styles.svg}
                                          >
                                            {isDarkMode ? (
                                              <DropdownLight />
                                            ) : (
                                              <Dropdown />
                                            )}
                                          </label>
                                          <ul
                                            className={`${styles.subList} dark-color`}
                                          >
                                            <li>
                                              <label
                                                htmlFor={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}`}
                                              >
                                                {isDarkMode ? (
                                                  <DarkBack />
                                                ) : (
                                                  <Back />
                                                )}
                                                <span>back</span>
                                              </label>
                                            </li>

                                            {subSection.sections.map(
                                              (
                                                subSubSection,
                                                subSubSectionIndex
                                              ) => (
                                                <li key={subSubSectionIndex}>
                                                  <Link
                                                    to={`/${
                                                      subSubSection.target
                                                        ?.slug ||
                                                      subSubSection.slug
                                                    }`}
                                                    onClick={() =>
                                                      closeMain(false)
                                                    }
                                                  >
                                                    {subSubSection.title}
                                                  </Link>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )
            }
          })} */}

          {/* revised Code v-2 */}

          {/* {headerItems.map((item, index) => {
            console.log("headerItems", headerItems)
            switch (item.__typename) {
              case "ContentfulComponentLink":
                return (
                  <li key={index}>
                    <Link
                      to={`/${useLink(item.target?.slug || item.slug)}`}
                      onClick={() => closeMain(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              case "ContentfulComponentLinkGroup":
                return (
                  <li key={index}>
                    {item.link.target?.slug || item.link.slug ? (
                      <Link
                        to={`/${useLink(
                          item.link.target?.slug || item.link.slug
                        )}`}
                        onClick={() => closeMain(false)}
                      >
                        {item.link.title}
                      </Link>
                    ) : (
                      <Link onClick={() => closeMain(false)}>
                        {item.link.title}
                      </Link>
                    )}
                    <input
                      type="checkbox"
                      name=""
                      className={`${styles.subListCheckbox} input-box `}
                      id={`mobile-header-${index}`}
                      hidden
                    />
                    {isDarkMode ? (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <DropdownLight />
                      </label>
                    ) : (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <Dropdown />
                      </label>
                    )}
                    <ul className={`${styles.subList} dark-color`}>
                      <li>
                        <label htmlFor={`mobile-header-${index}`}>
                          {isDarkMode ? <DarkBack /> : <Back />}
                          <span>back</span>
                        </label>
                      </li>
                      {item.sections.map((section, sectionIndex) => (
                        <li key={sectionIndex}>
                          <Link
                            to={`/${section.target?.slug || section.slug}`}
                            onClick={() => closeMain(false)}
                          >
                            {section.title}
                          </Link>
                          {section.sections && (
                            <>
                              <input
                                type="checkbox"
                                name=""
                                className={`${styles.subListCheckbox} input-box `}
                                id={`mobile-header-${index}-section-${sectionIndex}`}
                                hidden
                              />
                              {isDarkMode ? (
                                <label
                                  htmlFor={`mobile-header-${index}-section-${sectionIndex}`}
                                  className={styles.svg}
                                >
                                  <DropdownLight />
                                </label>
                              ) : (
                                <label
                                  htmlFor={`mobile-header-${index}-section-${sectionIndex}`}
                                  className={styles.svg}
                                >
                                  <Dropdown />
                                </label>
                              )}
                              <ul className={`${styles.subList} dark-color`}>
                                <li>
                                  <label
                                    htmlFor={`mobile-header-${index}-section-${sectionIndex}`}
                                  >
                                    {isDarkMode ? <DarkBack /> : <Back />}
                                    <span>back</span>
                                  </label>
                                </li>
                                {section.sections.map(
                                  (subSection, subSectionIndex) => (
                                    <li key={subSectionIndex}>
                                      Before ?
                                      <Link
                                        to={`/${
                                          subSection.target?.slug ||
                                          subSection.slug
                                        }`}
                                        onClick={() => closeMain(false)}
                                      >
                                        {subSection.title}
                                      </Link>
                                      {subSection.sections && (
                                        <>
                                          <input
                                            type="checkbox"
                                            name=""
                                            className={`${styles.subListCheckbox} input-box `}
                                            id={`mobile-header-${index}-section-${sectionIndex}-subSection-${subSectionIndex}`}
                                            hidden
                                          />
                                          {isDarkMode ? (
                                            <label
                                              htmlFor={`mobile-header-${index}-section-${sectionIndex}-subSection-${subSectionIndex}`}
                                              className={styles.svg}
                                            >
                                              <DropdownLight />
                                            </label>
                                          ) : (
                                            <label
                                              htmlFor={`mobile-header-${index}-section-${sectionIndex}-subSection-${subSectionIndex}`}
                                              className={styles.svg}
                                            >
                                              <Dropdown />
                                            </label>
                                          )}
                                          <ul
                                            className={`${styles.subList} dark-color`}
                                          >
                                            <li>
                                              <label
                                                htmlFor={`mobile-header-${index}-section-${sectionIndex}-subSection-${subSectionIndex}`}
                                              >
                                                {isDarkMode ? (
                                                  <DarkBack />
                                                ) : (
                                                  <Back />
                                                )}
                                                <span>back</span>
                                              </label>
                                            </li>
                                            {subSection.sections.map(
                                              (
                                                subSubSection,
                                                subSubSectionIndex
                                              ) => (
                                                <li key={subSubSectionIndex}>
                                                  Cwv Alalytics Level ?
                                                  <Link
                                                    to={`/${
                                                      subSubSection.target
                                                        ?.slug ||
                                                      subSubSection.slug
                                                    }`}
                                                    onClick={() =>
                                                      closeMain(false)
                                                    }
                                                  >
                                                    {subSubSection.title}
                                                  </Link>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )
            }
          })} */}

          {/* revised Code v-3 */}

          {headerItems.map((item, index) => {
            // console.log("headerItems", headerItems)
            switch (item.__typename) {
              case "ContentfulComponentLink":
                return (
                  <li key={index}>
                    <Link
                      to={`/${useLink(item.target?.slug || item.slug)}`}
                      onClick={() => closeMain(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              case "ContentfulComponentLinkGroup":
                return (
                  <li key={index}>
                    {item.link.target?.slug || item.link.slug ? (
                      <Link
                        to={`/${useLink(
                          item.link.target?.slug || item.link.slug
                        )}`}
                        onClick={() => closeMain(false)}
                      >
                        {item.link.title}
                      </Link>
                    ) : (
                      <Link onClick={() => closeMain(false)}>
                        {item.link.title}
                      </Link>
                    )}
                    <input
                      type="checkbox"
                      name=""
                      className={`${styles.subListCheckbox} input-box `}
                      id={`mobile-header-${index}`}
                      hidden
                    />
                    {isDarkMode ? (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <DropdownLight />
                      </label>
                    ) : (
                      <label
                        htmlFor={`mobile-header-${index}`}
                        className={styles.svg}
                      >
                        <Dropdown />
                      </label>
                    )}
                    <ul className={`${styles.subList} dark-color`}>
                      <li>
                        <label htmlFor={`mobile-header-${index}`}>
                          {isDarkMode ? <DarkBack /> : <Back />}
                          <span>back</span>
                        </label>
                      </li>

                      {item.sections.map((section, sectionIndex) => (
                        <li key={sectionIndex}>
                          <Link
                            to={`/${section.target?.slug || section.slug}`}
                            onClick={() => closeMain(false)}
                          >
                            {section.title}
                          </Link>
                          {section.sections && (
                            <>
                              <input
                                type="checkbox"
                                name=""
                                className={`${styles.subListCheckbox} input-box `}
                                id={`mobile-header-${index}-${sectionIndex}`}
                                hidden
                              />
                              <label
                                htmlFor={`mobile-header-${index}-${sectionIndex}`}
                                className={styles.svg}
                              >
                                {isDarkMode ? <DropdownLight /> : <Dropdown />}
                              </label>
                              <ul className={`${styles.subList} dark-color`}>
                                <li>
                                  <label
                                    htmlFor={`mobile-header-${index}-${sectionIndex}`}
                                  >
                                    {isDarkMode ? <DarkBack /> : <Back />}
                                    <span>back</span>
                                  </label>
                                </li>

                                {section.sections.map(
                                  (subSection, subSectionIndex) => (
                                    <li key={subSectionIndex}>
                                      <Link
                                        to={`/${subSection.target?.slug ||
                                          subSection.slug
                                          }`}
                                        onClick={() => closeMain(false)}
                                      >
                                        {subSection.title}
                                      </Link>
                                      {subSection.sections && (
                                        <>
                                          <input
                                            type="checkbox"
                                            name=""
                                            className={`${styles.subListCheckbox} input-box `}
                                            id={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}`}
                                            hidden
                                          />
                                          <label
                                            htmlFor={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}`}
                                            className={styles.svg}
                                          >
                                            {isDarkMode ? (
                                              <DropdownLight />
                                            ) : (
                                              <Dropdown />
                                            )}
                                          </label>
                                          <ul
                                            className={`${styles.subList} dark-color`}
                                          >
                                            <li>
                                              <label
                                                htmlFor={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}`}
                                              >
                                                {isDarkMode ? (
                                                  <DarkBack />
                                                ) : (
                                                  <Back />
                                                )}
                                                <span>back</span>
                                              </label>
                                            </li>

                                            {subSection.sections.map(
                                              (
                                                subSubSection,
                                                subSubSectionIndex
                                              ) => (
                                                <li key={subSubSectionIndex}>
                                                  <Link
                                                    to={`/${subSubSection.target
                                                        ?.slug ||
                                                      subSubSection.slug
                                                      }`}
                                                    onClick={() =>
                                                      closeMain(false)
                                                    }
                                                  >
                                                    {subSubSection.title}
                                                  </Link>
                                                  {subSubSection.sections && (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        name=""
                                                        className={`${styles.subListCheckbox} input-box `}
                                                        id={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}-${subSubSectionIndex}`}
                                                        hidden
                                                      />
                                                      <label
                                                        htmlFor={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}-${subSubSectionIndex}`}
                                                        className={styles.svg}
                                                      >
                                                        {isDarkMode ? (
                                                          <DropdownLight />
                                                        ) : (
                                                          <Dropdown />
                                                        )}
                                                      </label>
                                                      <ul
                                                        className={`${styles.subList} dark-color`}
                                                      >
                                                        <li>
                                                          <label
                                                            htmlFor={`mobile-header-${index}-${sectionIndex}-${subSectionIndex}-${subSubSectionIndex}`}
                                                          >
                                                            {isDarkMode ? (
                                                              <DarkBack />
                                                            ) : (
                                                              <Back />
                                                            )}
                                                            <span>back</span>
                                                          </label>
                                                        </li>

                                                        {subSubSection.sections.map(
                                                          (
                                                            subSubSubSection,
                                                            subSubSubSectionIndex
                                                          ) => (
                                                            <li
                                                              key={
                                                                subSubSubSectionIndex
                                                              }
                                                            >
                                                              <Link
                                                                to={`/${subSubSubSection
                                                                    .target
                                                                    ?.slug ||
                                                                  subSubSubSection.slug
                                                                  }`}
                                                                onClick={() =>
                                                                  closeMain(
                                                                    false
                                                                  )
                                                                }
                                                              >
                                                                {
                                                                  subSubSubSection.title
                                                                }
                                                              </Link>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    </>
                                                  )}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )
            }
          })}

          <li>
            <Link
              to={`/${useLink(headerButton.target?.slug || headerButton.slug)}`}
              onClick={() => closeMain(false)}
            >
              {headerButton.title}
            </Link>
          </li>
          <p onClick={() => closeMain(false)}>
            {profile &&
              ((profile.firstName && profile.firstName[0]) ||
                (profile.lastName && profile.lastName[0])) ? (
              <span className={styles.headerAvtar} id="header-profile-logo">
                <span
                  // onClick={() => { setOpenModal(!openModal) }}
                  className={styles.profile}
                >
                  <span>Hi, {profile?.firstName}</span>
                </span>
              </span>
            ) : (
              <span id="header-profile-logo">
                <Link to={`/${useLink("profile")}`}>
                  <img className={`styles.img`} alt="avtar" />
                </Link>
              </span>
            )}
          </p>
          <li>
            <div className={styles.profile}>
              <Link
                onClick={() => closeMain(false)}
                className={`styles.contentSeparateLink`}
                to={`/profile`}
              >
                Profile
              </Link>
            </div>
          </li>
          <li>
            <div className={`styles.logout`}>
              <span
                className={`styles.contentSeparateLink`}
                onClick={() => {
                  logout()
                  closeMain(false)
                }}
              >
                SignOut
              </span>
            </div>
          </li>
          {/* {showDarkModeToggle && (
            <div
              className={`dark-mode-toggle ${isDarkMode ? "dark-theme" : "light-theme"
                }`}
            >
              <input
                type="checkbox"
                id="dark-mode-toggle-input"
                checked={isDarkMode}
                onChange={handleDarkModeToggle}
              />
              <p className="dark-mode-switch">
                {isDarkMode ? "Dark Theme" : "Light Theme"}
              </p>
            </div>
          )} */}
        </ul>
      </div>
    </div>
  )
}

const SideMenu = ({ data: { link, sections } }) => {
  const [isHovered, setIsHovered] = useState(false)
  const linkSlug = link.target?.slug || link.slug

  const { isDarkMode, setIsDarkMode } = useContext(LayoutContext)
  // console.log("linkSlug", linkSlug)
  return (
    <li
      className={`${`styles.mainMenu`} ${linkSlug}`}
      id={link.title.toLowerCase()}
    >
      <div className={`${styles.listItem} list-item ${`styles.listItemMain`}`}>
        {linkSlug ? (
          <Link to={`/${useLink(linkSlug)}`}>{link.title}</Link>
        ) : (
          <Link>{link.title}</Link>
        )}

        {isDarkMode ? (
          <label className={styles.dropDownContainer}>
            <DropdownLight
              className={`${styles.arrow} ${isHovered ? styles.upArrow : styles.downArrow
                }`}
            />
          </label>
        ) : (
          <label className={styles.dropDownContainer}>
            <Dropdown
              className={`${styles.arrow} ${isHovered ? styles.upArrow : styles.downArrow
                }`}
            />
          </label>
        )}
      </div>
      <div className={`${styles.categoryMenu} dark-color `}>
        <ul className={styles.subList}>
          {sections.map(({ title, slug, target }, i) => (
            <li className={styles.count} key={i}>
              <p
                className={` ${styles.menuBarLink} ${slug ? `styles.unlistItem` : ""
                  } `}
                key={i}
              >
                <Link to={`/${useLink(target?.slug || slug)}`}>{title}</Link>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

export default Header
